<template>
    <label :for="name" class="font-medium text-900">{{ label }}</label>
    <Button v-if="showClearButton" class="p-button-rounded p-button-danger p-button-text" icon="pi pi-sync"
            @click="$emit('clear')">
    </Button>
    <InputText v-if="component === 'InputText'" :id="name" :class="shouldDisplayErrors ? 'p-invalid' : ''" v-bind="$attrs"/>
    <Password v-else-if="component === 'Password'" :id="name" :class="shouldDisplayErrors ? 'p-invalid' : ''" v-bind="$attrs">
        <template #header>
            <h3>Pick a password</h3>
        </template>
        <template #footer>
            <Divider/>
            <p class="mt-2">Suggestions</p>
            <ul class="pl-2 ml-2 mt-0" style="line-height: 1.5">
                <li>At least one lowercase</li>
                <li>At least one uppercase</li>
                <li>At least one numeric</li>
                <li>Minimum 8 characters</li>
            </ul>
        </template>
    </Password>
    <Dropdown v-else-if="component === 'Dropdown'" :id="name" :class="shouldDisplayErrors ? 'p-invalid' : ''" v-bind="$attrs"/>
    <MultiSelect v-else-if="component === 'MultiSelect'" :id="name" :class="shouldDisplayErrors ? 'p-invalid' : ''" v-bind="$attrs"/>
    <Calendar v-else-if="component === 'Calendar'" :id="name" :class="shouldDisplayErrors ? 'p-invalid' : ''" dateFormat="dd/mm/yy" v-bind="$attrs"/>
    <Editor v-else-if="component === 'Editor'" :id="name" :class="shouldDisplayErrors ? 'p-invalid' : ''" editorStyle="height: 500px" v-bind="$attrs">
    </Editor>
    <SelectButton v-else-if="component === 'SelectButton'" :id="name" :class="shouldDisplayErrors ? 'p-invalid' : ''" style="width:130px; " v-bind="$attrs"/>
    <InputNumber v-else-if="component === 'InputNumber'" :id="name" :class="shouldDisplayErrors ? 'p-invalid' : ''" style="width:130px;" v-bind="$attrs"/>
    <Textarea v-else-if="component === 'Textarea'" :id="name" :class="shouldDisplayErrors ? 'p-invalid' : ''" v-bind="$attrs"/>
    <InputSwitch v-else-if="component === 'InputSwitch'" :id="name" :class="shouldDisplayErrors ? 'p-invalid' : ''" v-bind="$attrs"/>
    <FileUpload v-else-if="component === 'File'" :id="name" :class="shouldDisplayErrors ? 'p-invalid' : ''" style="width:130px;" v-bind="$attrs"/>
    <small v-if="shouldDisplayErrors" :id="name+'-help'" class="p-error">{{ $page.props.errors[name] }}</small>
</template>

<script>
import {defineAsyncComponent} from 'vue';

export default {
    components : {
        Dropdown     : defineAsyncComponent(() => import('primevue/dropdown')),
        InputNumber  : defineAsyncComponent(() => import('primevue/inputnumber')),
        Password     : defineAsyncComponent(() => import('primevue/password')),
        MultiSelect  : defineAsyncComponent(() => import('primevue/multiselect')),
        Calendar     : defineAsyncComponent(() => import('primevue/calendar')),
        Divider      : defineAsyncComponent(() => import('primevue/divider')),
        Textarea     : defineAsyncComponent(() => import('primevue/textarea')),
        InputSwitch  : defineAsyncComponent(() => import('primevue/inputswitch')),
        Editor       : defineAsyncComponent(() => import('primevue/editor')),
        SelectButton : defineAsyncComponent(() => import('primevue/selectbutton')),
        FileUpload   : defineAsyncComponent(() => import('primevue/fileupload'))
    },
    props      : {
        label           : String,
        name            : String,
        displayErrors   : {
            type    : Boolean,
            default : true
        },
        component       : {
            type    : String,
            default : 'InputText'
        },
        showClearButton : {
            type    : Boolean,
            default : false
        }
    },
    computed   : {
        shouldDisplayErrors() {
            return this.$page.props.errors[this.name] && this.displayErrors;
        },
    },
}
</script>
